<template>
   <list-container-widget
    id="conference"
    :title="widgetName"
    :app-id="113"
    stretch
    class="d-flex"
  >
      <template #actions>
      <!-- Panel actions -->
      <widget-actions
        item-type="conference"
        :show-list="false"
        :show-add="false"
        :show-filter="false"
      />
    </template>
    <!-- Loading -->
    <b-card-body>
      <!-- Container with data -->
      <div class="room-card">
           <component
        :is="currentComponent"
        :room="room"
        :initial-configuration="initialConfiguration"
        @join-meeting="handleJoinMeeting"
        @leave-meeting="handleLeaveMeeting"
        @go-preparing="handleGoPreparing"
      />
       
      </div>
    </b-card-body>
  </list-container-widget>
</template>

<script>
import ListContainerWidget from '@core/widgets/ListContainerWidget.vue';
import WidgetActions from '@/@core/components/widget-actions/WidgetActions.vue';
import WidgetLayoutMixin from '@/@core/mixins/widgets/WidgetLayoutMixin';
import { RoomProvider } from '@copernicsw/community-common';
import RoomPreparationMeeting from '@/views/apps/rooms/components/room-details/RoomPreparationMeeting.vue';
import RoomStartMeeting from '@/views/apps/rooms/components/room-details/RoomStartMeeting.vue';
import RoomClosedMeeting from '@/views/apps/rooms/components/room-details/RoomClosedMeeting.vue';

const MeetingSteps = Object.freeze({
  preparation: 'meeting-step-preparation',
  start: 'meeting-step-start',
  closed: 'meeting-step-close',
});


export default { 
  name: 'ConferenceWidget',
  components: {     
    RoomPreparationMeeting,
    RoomStartMeeting,
    RoomClosedMeeting,ListContainerWidget, WidgetActions},
  mixins: [WidgetLayoutMixin],
    props: {
  },
  data() {
    return {
      currentStep: MeetingSteps.preparation,
      initialConfiguration: {
        isMicOn: true,
        isVideoOn: true,
      },
    };
  },
  computed: {
        currentComponent() {
      switch (this.currentStep) {
        case MeetingSteps.preparation:
          return [RoomProvider.bbb, RoomProvider.bbbUsingCommunityKey].includes(this.room.app) ? BigBlueButton : RoomPreparationMeeting;
        case MeetingSteps.start:
          return RoomStartMeeting;
        case MeetingSteps.closed:
          return RoomClosedMeeting;
        default:
          // should never happen.
          return null;
      }
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    room(){
      return {name:this.currentCollective.name, app:'Jisti', type:'webinar', modality:'online', widget:true}
    }
  },
  methods: {
    handleJoinMeeting(config) {
      this.currentStep = MeetingSteps.start;
      if (config) {
        this.initialConfiguration = config;
      }
    },
    handleLeaveMeeting() {
      this.currentStep = MeetingSteps.closed;
    },
    handleGoPreparing() {
      this.currentStep = MeetingSteps.preparation;
    },
  }

}
</script>

<style lang="scss">
  @import "@/views/apps/rooms/scss/rooms.scss";
</style>
